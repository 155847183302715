.pt-5 {
  padding-top: 100px;
}

.pt-4 {
  padding-top: 80px;
}

.pt-1 {
  padding-top: 18px;
}

html,
body {
  min-height: 100%;
}

@media only screen and (max-width: 600px) {
  .grecaptcha-badge {
    visibility: hidden;
  }
  #rs_recaptcha_terms_and_conditions {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .grecaptcha-badge {
    visibility: visible;
  }
  #rs_recaptcha_terms_and_conditions {
    display: none;
  }
}

.reachship-logo-auth {
  height: 40px;
  width: 150px;
  padding-top: 15px;
  display: block;
}

.reachship-large-logo-auth-elex-caption {
  height: auto;
  width: 120px;
  padding-left: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.reachship-small-logo-auth-elex-caption {
  height: auto;
  width: 90px;
  padding-left: 10px;
  padding-bottom: 5px;
}

.reachship-logo-auth-elex-caption {
  height: auto;
  width: 90px;
  padding-left: 10px;
}

.reachship-auth-background {
  background-color: #e8faff;
  min-height: 100vh;
}

.reachship-dashboard-background {
  background-color: #e8faff;
  min-height: 100vh;
}

.reachship-pricing-background{
  background-color: #fff;
  min-height: 100vh;
}

* {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.MuiModal-root {
  overflow: scroll!important;
}

.inline-block {
  display: inline-block;
  height: auto;
  margin: 10px;
}

.ib {
  display: inline-block;
}

.cp {
  cursor: pointer;
}

.MuiTypography-h4,
.MuiTypography-h6 {
  color: #011f4b;
}

.reachship-auth-container-align {
  margin: 0px auto;
}

.reachship-card-container-align {
  margin: 25px auto;
  padding-left: 13%;
}

/* To modify react phone input default. */
.react-tel-input .form-control:focus {
  border-color: #011f4b !important;
  box-shadow: 0 0 0 1px #011f4b !important;
}

/** Material UI input label and border overlap issue. */
label.MuiFormLabel-root {
  background-color: white;
  width: auto;
}

.newline {
  margin: 10px;
}

.newline2 {
  margin: 20px;
}

/* Plans Card */
label {
  width: 100%;
}

.card-input-element {
  display: none;
}

.card-input {
  margin: 10px;
  padding: 10px;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.card-input:hover {
  cursor: pointer;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.appbar-links {
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  padding-left: 1.5rem;
  color: black;
}

.menuitem-links {
  font-weight: 400;
  line-height: 1;
  color: black;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.disable_scroll {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.MuiTooltip-popper {
  z-index: 9999999 !important;
}
